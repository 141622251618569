// src/components/LoginRegister.jsx
import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';


export default function LoginRegister() {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Handle Email/Password Login
    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setError(null); // Clear any previous errors
        } catch (error) {
            setError(error.message);
        }
    };

    // Handle Email/Password Register
    const handleRegister = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setError(null); // Clear any previous errors
        } catch (error) {
            setError(error.message);
        }
    };

    // Handle Google Sign-In
    const handleGoogleLogin = async () => {
        try {
            await signInWithPopup(auth, provider);
            setError(null); // Clear any previous errors
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <div className="max-w-md mx-auto bg-white shadow-md rounded p-6 mb-16 mt-24">
                <div className="text-center mb-6">
                    <h2 className="text-2xl font-bold">
                        {isLogin ? 'Login' : 'Register'}
                    </h2>
                </div>

                {/* Tabs for Login / Register */}
                <div className="flex justify-center mb-4">
                    <button 
                        className={`px-4 py-2 ${isLogin ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded-l`}
                        onClick={() => setIsLogin(true)}
                    >
                        Login
                    </button>
                    <button 
                        className={`px-4 py-2 ${!isLogin ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded-r`}
                        onClick={() => setIsLogin(false)}
                    >
                        Register
                    </button>
                </div>

                {/* Error Message */}
                {error && (
                    <div className="text-red-500 text-sm mb-4">
                        {error}
                    </div>
                )}

                {/* Email/Password Form */}
                <div className="mb-4">
                    <label className="block text-gray-700">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border rounded"
                        placeholder="Enter your email"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700">Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border rounded"
                        placeholder="Enter your password"
                    />
                </div>

                {/* Email/Password Authentication Button */}
                <div className="mb-4">
                    <button
                        onClick={isLogin ? handleLogin : handleRegister}
                        className="w-full bg-blue-500 text-white py-2 rounded"
                    >
                        {isLogin ? 'Login' : 'Register'}
                    </button>
                </div>

                {/* Google Sign-In Button */}
                <div className="text-center mb-4">
                    <p>or</p>
                    <button
                        onClick={handleGoogleLogin}
                        className="w-full bg-red-500 text-white py-2 rounded mt-2"
                    >
                        Sign in with Google
                    </button>
                </div>
            </div>
        </>
    );
}
